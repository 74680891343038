<template>
    <div class="w-1/2 p-2">
        <div class="border rounded shadow p-5">
            <div class="border-b p-3 flex justify-between align-center">
                <div>
                    <p class="uppercase text-gray-600">{{ name }}</p>
                    <p class="font-extralight text-sm">Total: {{ calc_nb_data(data) }}</p>
                </div>
                <div>
                    <p class="font-extralight">{{ days }} jours</p>
                </div>
            </div>
            <canvas :id="id"></canvas>
        </div>
    </div>
</template>

<script>
import axiosGet from '@/assets/script/axios.js'
import Chart from 'chart.js'

export default {
    name: 'LineChart',
    props: [
        'id',
        'type',
        'fill',
        'url',
        'backgroundColor',
        'borderColor',
        'borderWidth',
        'height',
        'width',
        'company',
        'days',
        'name',
    ],
    data: function () {
        return ({
            data: [],
            labels: [],
            chartInstance: null,
            displayTicks: true,
        })
    },
    methods: {
        mountChart(){
            var ctx = document.getElementById(this.id).getContext('2d')

            this.chartInstance = new Chart(ctx, {
                type: this.type ? this.type : 'line',
                data: {
                    labels: this.labels,
                    datasets: [{
                        data: this.data,
                        fill: this.fill,
                        label: this.title,
                        backgroundColor: this.backgroundColor,
                        borderColor: this.borderColor ? this.borderColor : "#000000",
                        borderWidth: this.borderWidth ? this.borderWidth : 1
                    }]
                },
                options: {
                    legend: {
                        display: true,
                    },
                    tooltips: {
                        enabled: true,
                    },
                    scales: {
                        xAxes: [{
                            ticks: {
                                display:(this.days > 30) ? false : true,
                                autoSkip: false,
                                maxRotation: 90,
                                minRotation: 90
                            }
                        }],
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                // stepSize: 1
                            }
                        }]
                    },
                }
            });
        },
        async updateChartData () {
            await axiosGet.get(this.url, {
                params: {
                    last_days: this.days ? this.days : '',
                    company: this.company ? this.company: '',
                },
            })
            .then((response) => {
                this.data = response.data.data
                this.labels = response.data.labels
                this.chartInstance.data.datasets.forEach((dataset) => {
                    dataset.data = this.data
                })
                this.chartInstance.data.labels = this.labels
                this.chartInstance.options.scales.xAxes[0].ticks.display = this.displayTicks
                this.chartInstance.update()
            })
        },
        async getData () {
            await axiosGet.get(this.url, {
                params: {
                    last_days: this.days ? this.days : '',
                    company: this.company ? this.company: '',
                },
            })
            .then((response) => {
                this.data = response.data.data
                this.labels = response.data.labels
                this.mountChart()
            })
        },
        calc_nb_data (data) {
            let nb = 0

            data.forEach(element => {
                nb += element
            })
            return nb
        },
    },
    computed: {
        title() {
            return 'Connexions'
        },
    },
    watch: {
        days () {
            this.updateChartData()
            this.displayTicks = (this.days > 30) ? false : true
        },
        company () {
            this.updateChartData()
        }
    },
    mounted () {
        this.getData()
    }
}
</script>

<style scoped>
</style>