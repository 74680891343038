<template>
    <div class="w-1/2 p-2">
        <div class="border rounded shadow p-5">
            <div class="border-b p-3 flex justify-between align-center">
                <div>
                    <p class="uppercase text-gray-600">{{ name }}</p>
                    <p class="font-extralight text-sm">Total: {{ calc_nb_data(data) }}</p>
                </div>
                <div>
                    <p class="font-extralight">{{ days }} jours</p>
                </div>
            </div>
            <canvas @click="chartClicked" :id="id"></canvas>
        </div>
    </div>
</template>

<script>
import axiosGet from '@/assets/script/axios.js'
import Chart from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.plugins.unregister(ChartDataLabels);

export default {
    name: 'PieChart',
    props: [
        'id',
        'type',
        'fill',
        'url',
        'backgroundColor',
        'borderColor',
        'borderWidth',
        'height',
        'width',
        'company',
        'days',
        'name',
    ],
    data: function () {
        return ({
            data: [],
            labels: [],
            chartInstance: null,
        })
    },
    methods: {
        mountChart(){
            var ctx = document.getElementById(this.id).getContext('2d');
            this.chartInstance = new Chart(ctx, {
                type: this.type ? this.type : 'doughnut',
                plugins: [ChartDataLabels],
                data: {
                    labels: this.labels,
                    datasets: [{
                        // label: this.title,
                        data: this.data,
                        backgroundColor: [
                            "rgba(248, 113, 113)",
                            "rgba(251, 191, 36)",
                            "rgba(52, 211, 153)",
                            "rgba(96, 165, 250)",
                            "rgba(153, 102, 255)",
                        ],
                        // borderColor: this.borderColor,
                        borderWidth: this.borderWidth ? this.borderWidth : 1
                    }]
                },
                options: {
                    plugins: {
                        datalabels: {
                            color: '#fff',
                            display: function(context) {
                                return context.dataset.data[context.dataIndex] > 0
                            },
                            font: {
                                size: 15
                            }
                        }
                    },
                    tooltips: {
                        enabled: false,
                    },
                }
            });
        },
        async updateChartData () {
            await axiosGet.get(this.url, {
                params: {
                    last_days: this.days ? this.days : '',
                    company: this.company ? this.company: '',
                },
            })
            .then((response) => {
                this.data = response.data.data
                this.labels = response.data.labels
                this.chartInstance.data.datasets.forEach((dataset) => {
                    dataset.data = this.data
                })
                this.chartInstance.data.labels = this.labels
                this.chartInstance.update()
            })
        },
        async getData () {
            await axiosGet.get(this.url, {
                params: {
                    last_days: this.days ? this.days : '',
                    company: this.company ? this.company: '',
                },
            })
            .then((response) => {
                this.data = response.data.data
                this.labels = response.data.labels
                this.mountChart()
            })
        },
        calc_nb_data (data) {
            let nb = 0

            data.forEach(element => {
                nb += element
            })
            return nb
        },
        chartClicked(event){
            if (this.company) { return }
            let datapoint = this.chartInstance.getElementAtEvent(event)[0]
            if (datapoint != undefined){
                let type = this.chartInstance.data.labels[datapoint._index]
                axiosGet.get(`${this.url}${type}/${this.days}/`, {
                }).then (response => {
                    let data = ''
                    response.data.forEach(element => {
                        data += `${element.label} : ${element.value}\n`
                    })
                    alert(data)
                })
            }
        }
    },
    computed: {
        title() {
            return 'Connexions'
        }
    },
    watch: {
        days () {
            this.updateChartData()
        },
        company () {
            this.updateChartData()
        }
    },
    mounted () {
        this.getData()
    }
}
</script>

<style scoped>
</style>