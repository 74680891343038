import axios from 'axios'
import { router, jwt_decode, token_exp } from '@/router/index.js'


const test_token = () => {
    const decode = jwt_decode(localStorage.getItem('refreshToken'))
    
    if (decode && !token_exp(decode.exp)) {
        localStorage.removeItem('accessToken')
        localStorage.removeItem('refreshToken')
        return false
    }
    return true
}

let buildToken = () => {
    const decode = jwt_decode(localStorage.getItem('accessToken'))
    
    if (!localStorage.getItem('accessToken') || (decode && !token_exp(decode.exp))) {
        localStorage.removeItem('accessToken')
        if (!test_token())
        router.push('/')
        return false
    }
    return true
}

let axiosGet
if (process.env.NODE_ENV === 'development') {
    axiosGet = axios.create({baseURL: 'http://localhost:8000'})
    axios.defaults.baseURL = 'http://localhost:8000'
} else {
    axiosGet = axios.create({baseURL: 'https://analytics.daiteo.io'})
    axios.defaults.baseURL = 'https://analytics.daiteo.io'
}

axiosGet.interceptors.request.use(
    async config => {
        let token
        if (buildToken())
            token = localStorage.getItem('accessToken')
        else {
            let payload = {'refresh': localStorage.getItem('refreshToken')}
            let response = await axios.post('/api/token/refresh/', payload)
            localStorage.setItem('accessToken', response.data.access)
            token = response.data.access
        }
        config.headers.Authorization = 'Bearer ' + token
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

export default axiosGet